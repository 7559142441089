import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private lastNonAuthUrl: string = '/'; // По умолчанию перенаправляем на '/cabinet'

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!event.url.startsWith('/auth')) {
          this.lastNonAuthUrl = event.url;
        }
      });
  }

  public getLastNonAuthUrl(): string {
    return this.lastNonAuthUrl;
  }
}
