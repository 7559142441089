import { Model } from '../abstract/model.abstract';
import { UserProfileModel } from './user-profile.model';
import { UserRoleModel } from './user-role.model';
import { EmployeesModel } from './employees.model';
import { UserEnterpriseProfileModel } from './user-enterprise-profile.model';
import { UserExportCatalogProfileModel } from './user-export-catalog-profile.model';
import { UserExportProfileModel } from './user-export-profile-model';
import { UserLmsTeacherProfileModel } from './user-lms-teacher-profile-model';

export class UserModel extends Model {
  id!: number;
  statusId!: number;
  email!: string;
  googleEnabled: boolean;
  createdAt!: string;
  updatedAt: string;
  fullName: string;
  roles: UserRoleModel[];
  profile: UserProfileModel;
  enterpriseProfile: UserEnterpriseProfileModel;
  exportCatalogProfile: UserExportCatalogProfileModel;
  profileEmployee: EmployeesModel;
  exportProfile: UserExportProfileModel;
  lmsTeacherProfile: UserLmsTeacherProfileModel;

  // auth data
  token?: string;
  refreshToken?: string;

  get profileIsCompleted() {
    return this.profile && (this.enterpriseProfile || this.profileEmployee) && this.exportProfile;
  }

  override onInit() {
    this.fullName =
      `${this?.profile?.lastName || ''} ${this?.profile?.firstName || ''} ${this?.profile?.middleName || ''}`.trim();
  }
}
