import { inject, Injectable, signal } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { catchError, tap } from 'rxjs/operators';
import { interval, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserModel } from '../models/user.models';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserStatus } from '../enums/user-status.enum';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser = signal<UserModel | null>(null);
  refreshInProgress = false;
  isInitialized = signal<boolean>(false);
  private redirectUrl: string | null = null;
  private apiAuthUrl = `${environment.api.url}/auth`;
  private interval$: Subscription;
  private localStorageService = inject(LocalStorageService);
  private router = inject(Router);
  private http = inject(HttpClient);
  private navigationService = inject(NavigationService);

  private get isTokenExp(): boolean {
    const token = this.localStorageService.getItem('token');
    if (!token) return false;

    const { exp } = jwtDecode<{ exp: number }>(token);
    const expirationTime = exp * 1000;
    const now = moment().utc().valueOf();

    return now >= expirationTime;
  }

  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  login(data: { email: string; password: string }): Observable<UserModel> {
    const url = this.getRedirectUrl();
    this.redirectUrl = null;
    return this.authRequest('login', data).pipe(
      tap({
        next: user => {
          this.setUserFromData(user);
          this.handleLogin(user);
        },
      })
    );
  }

  private handleError() {
    this.refreshInProgress = false;
    this.logout();
  }

  startRefreshInterval() {
    if (this.interval$) {
      this.interval$.unsubscribe();
    }

    this.interval$ = interval(1000).subscribe(() => {
      if (this.isTokenExp) this.refresh().subscribe();
    });
  }

  beforeRequest(): Observable<any> {
    return this.isTokenExp ? this.refresh() : of({});
  }

  refresh(): Observable<UserModel | null> {
    if (this.refreshInProgress) return of(null);

    this.refreshInProgress = true;
    return this.http
      .post<UserModel | null>(`${this.apiAuthUrl}/refresh`, {
        refreshToken: this.localStorageService.getItem('refreshToken'),
      })
      .pipe(
        tap({
          next: user => {
            if (user) {
              this.setUserFromData(user);
            } else {
              this.handleError();
            }
          },
          error: () => this.handleError(),
          finalize: () => (this.refreshInProgress = false),
        }),
        catchError(() => {
          this.handleError();
          return of(null);
        })
      );
  }

  recovery(data: { email: string }): Observable<any> {
    return this.authRequest('recovery', data);
  }

  passwordReset(data: { password: string; token: string }): Observable<any> {
    return this.authRequest('password-reset', data);
  }

  employeeSignup(data: { password: string; token: string }): Observable<any> {
    return this.authRequest('employee-signup', data);
  }

  googleAuth(token: string): Observable<UserModel> {
    const url = this.getRedirectUrl();
    this.redirectUrl = null;
    return this.authRequest('google', { token }).pipe(
      tap({
        next: user => {
          this.setUserFromData(user);
          this.handleLogin(user);
        },
      })
    );
  }

  signup(data: any): Observable<UserModel> {
    return this.authRequest('signup', data);
  }

  getMe(): Observable<UserModel> {
    return this.http
      .get<UserModel>(`${this.apiAuthUrl}/user`)
      .pipe(tap(user => this.setUserFromData(user)));
  }

  private setUserFromData(user: UserModel): void {
    if (user.token) {
      this.localStorageService.setItem('token', user.token);
      this.localStorageService.setItem('refreshToken', user.refreshToken);
      this.currentUser.set(UserModel.fromJson(user as UserModel));
      this.startRefreshInterval();
    }
  }

  initializeUserState(): Promise<UserModel | null> {
    const token = this.localStorageService.getItem('token');
    if (!token) {
      return Promise.resolve(null);
    }

    return new Promise((resolve, reject) => {
      const observable = this.isTokenExp ? this.refresh() : this.getMe();
      observable.subscribe({
        next: user => {
          this.currentUser.set(UserModel.fromJson(user as UserModel));
          this.startRefreshInterval();
          resolve(user);
        },
        error: err => {
          this.logout();
          resolve(null);
        },
      });
    });
  }

  logout() {
    this.currentUser.set(null);
    this.localStorageService.removeItem('chatUid');
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('refreshToken');
    this.router.navigate(['/']);
  }

  private authRequest(endpoint: string, data: any): Observable<UserModel> {
    return this.http
      .post<UserModel>(`${this.apiAuthUrl}/${endpoint}`, data)
      .pipe(tap(user => this.setUserFromData(user)));
  }

  private handleLogin(user: UserModel, noRedirect: boolean = false): void {
    const userStatusRedirectMap: Record<UserStatus, string> = {
      [UserStatus.Step1]: '/auth/signup',
      [UserStatus.Step2]: '/auth/signup/step-two',
      [UserStatus.Step3]: '/auth/signup/step-three',
      [UserStatus.Step4]: '/auth/signup/step-four',
      [UserStatus.Active]: '/cabinet',
      [UserStatus.Blocked]: '/auth/blocked',
    };
    const currentUserStatus = user.statusId as UserStatus;
    const defaultRoute = userStatusRedirectMap[currentUserStatus] || '/cabinet';
    if (noRedirect) {
      return;
    }
    if (currentUserStatus !== UserStatus.Active || defaultRoute !== '/cabinet') {
      this.router.navigate([defaultRoute]);
      return;
    }
    const previousUrl = this.navigationService.getLastNonAuthUrl();
    const isInvalidPreviousUrl =
      !previousUrl || previousUrl === '/' || previousUrl.startsWith('/auth');
    this.router.navigate([isInvalidPreviousUrl ? '/cabinet' : previousUrl]);
  }
}
